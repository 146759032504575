import styled from 'styled-components';
import { zindex } from 'app/shared/styles/_zindex';
import { colors } from 'app/shared/styles/_colors';
import { viewports } from 'app/shared/styles/_breakpoints';
import { font } from 'app/shared/styles/_fontsize';

export const LoginStatus = styled.ul`
  ${font.sm};
  z-index: ${zindex['$z-index-base']};
  position: absolute;
  top: 0px;
  right: 0px;
  min-width: 150px;
  text-align: right;
  height: 40px;
  @media ${viewports['md-and-up']} {
    min-width: 172px;
    height: 56px;
  }
`;

export const LoginStatusProperties = styled.li`
  display: none;
  @media ${viewports['lg-and-up']} {
    border-right: 1px solid ${colors['$hpx-grey-500']};
    display: inline-block;
    padding-right: 12px;
    margin-right: 12px;
  }
`;

export const LoginStatusGroup = styled.div`
  padding-top: 3px;
  @media ${viewports['md-and-up']} {
    padding-top: 0px;
  }
`;

export const LoginStatusLink = styled.li`
  height: 100%;
  display: inline-block;
  padding-right: 16px;
  line-height: 40px;
  @media ${viewports['md-and-up']} {
    line-height: 56px;
  }
`;

export const LoginStatusProfileIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  top: -1px;
  position: relative;
`;
