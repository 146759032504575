import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

export const ConversationBadge = styled.div`
  background-color: ${colors['$hpx-red-500']};
  border-radius: 4px;
  color: ${colors['$hpx-white']};
  display: inline;
  font-size: 14px;
  line-height: 1.1;
  margin-left: 4px;
  padding: 3px 4px 2px 4px;
`;
