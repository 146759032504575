// ConversationBadge.tsx
import type { FC, ReactNode } from 'react';
import React from 'react';
import * as S from './styles';

// Define the interface for the props
interface ConversationBadgeProps {
  'aria-label'?: string;
  children?: ReactNode;
}

const ConversationBadge: FC<ConversationBadgeProps> = ({ 'aria-label': ariaLabel, children }) => {
  return <S.ConversationBadge aria-label={ariaLabel}>{children}</S.ConversationBadge>;
};

export default ConversationBadge;
